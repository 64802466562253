.skills {
  padding: 3rem 2rem;

  h2 {
    text-align: center;
    font-size: 2rem;
    color: #334155;
    margin-bottom: 2rem;
  }

  &__category {
    margin-bottom: 2rem;

    h3 {
      color: #6366F1;
      margin-bottom: 1rem;
    }

    .skills__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 1rem;

      .skill-item {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.05);
        text-align: center;
        padding: 1rem;
        transition: transform 0.3s ease;

        &.hidden {
          opacity: 0;
          transform: translateY(20px);
        }

        &.show {
          opacity: 1;
          transform: translateY(0);
          transition: all 0.6s ease;
        }

        img {
          height: 40px;
          margin-bottom: 0.5rem;
        }

        span {
          display: block;
          font-size: 0.875rem;
          color: #64748B;
        }
      }
    }
  }
}
