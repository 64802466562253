// Palette de couleurs
$bg-dark: #111827;       // fond général sombre
$bg-light: #1f2937;      // blocs internes
$text-light: #f3f4f6;    // texte principal
$text-muted: #9ca3af;    // texte secondaire
$accent: #4f46e5;        // violet / accent

// Typo & layout
$font-main: 'Poppins', sans-serif;
$radius: 8px;
$shadow: 0 0 10px rgba(0, 0, 0, 0.15);

// Breakpoints
$bp-md: 768px;
$bp-lg: 1024px;
