.contact {
  padding: 3rem 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.05);
  max-width: 600px;
  margin: 2rem auto;

  &__title {
    text-align: center;
    font-size: 2rem;
    color: #334155;
    margin-bottom: 1.5rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & .contact__field {
      padding: 0.75rem;
      border: 1px solid #E5E7EB;
      border-radius: 6px;
      transition: border-color 0.3s;

      &:focus {
        border-color: #6366F1;
        outline: none;
      }
    }

    & .contact__button {
      background-color: #6366F1;
      color: white;
      padding: 0.75rem;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #4F46E5;
      }
    }

    & .contact__status {
      text-align: center;
      margin-top: 0.5rem;
      color: #10B981; // Vert pour succès, rouge (#EF4444) si erreur
    }
  }
}
