.projects {
  padding: 3rem 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.05);
  max-width: 1000px;
  margin: 2rem auto;

  &__title {
    font-size: 2rem;
    color: #334155;
    text-align: center;
    margin-bottom: 2rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 1.5rem;
  }

  &__item {
    background-color: #F9FAFB;
    border-radius: 8px;
    padding: 1.5rem;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    }

    h3 {
      font-size: 1.25rem;
      color: #6366F1;
      margin-bottom: 0.5rem;
    }

    p {
      color: #64748B;
      margin-bottom: 0.5rem;
    }

    a {
      display: inline-block;
      margin-top: 0.5rem;
      color: #6366F1;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        color: #4F46E5;
      }
    }
  }
}
