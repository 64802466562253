.layout {
    display: flex;
    min-height: 100vh;
    background-color: #F9FAFB;
  
    .main-content {
      flex: 1;
      padding: 2rem;
      overflow-x: hidden;
    }
  }
  
  .sidebar {
    width: 240px;
    background-color: #ffffff;
    border-right: 1px solid #E5E7EB;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 0;
    height: 100vh;
  
    &__logo {
      font-size: 1.5rem;
      font-weight: 700;
      color: #6366F1;
      text-align: center;
      margin-bottom: 2rem;
    }
  
    &__nav {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      a {
        text-decoration: none;
        color: #334155;
        font-weight: 500;
        padding: 0.5rem 1rem;
        border-radius: 6px;
        transition: background 0.2s;
  
        &.active,
        &:hover {
          background-color: #E0E7FF;
          color: #4F46E5;
        }
      }
    }
  
    &__footer {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0 0.5rem;
      a {
        font-size: 0.95rem;
        color: #94A3B8;
        text-align: center;
        word-break: break-word;
        width: 100%;
        max-width: 200px;
        transition: color 0.2s;
  
        &:hover {
          color: #6366F1;
        }
      }
    }
  }