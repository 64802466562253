.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding: 2rem;

  &__container {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.05);
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  &__title {
    font-size: 1.75rem;
    color: #334155;
    margin-bottom: 1.5rem;
  }

  &__input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    margin-bottom: 1rem;

    &:focus {
      border-color: #6366F1;
      outline: none;
    }
  }

  &__button {
    width: 100%;
    background-color: #6366F1;
    color: white;
    padding: 0.75rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #4F46E5;
    }
  }
}
