.blog {
  padding: 3rem 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  max-width: 800px;
  margin: 2rem auto;

  &__title {
    font-size: 2rem;
    color: #334155;
    text-align: center;
    margin-bottom: 2rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    .blog__field {
      padding: 0.75rem;
      border: 1px solid #E5E7EB;
      border-radius: 6px;
      &:focus {
        border-color: #6366F1;
        outline: none;
      }
    }

    .blog__button {
      background-color: #6366F1;
      color: white;
      padding: 0.75rem;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #4F46E5;
      }
    }
  }

  &__status {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #10B981;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .blog__item {
      padding: 1.25rem;
      border-radius: 8px;
      background-color: #F9FAFB;
      transition: transform 0.3s, box-shadow 0.3s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0,0,0,0.1);
      }

      &-title {
        font-size: 1.25rem;
        color: #334155;
      }

      &-content {
        color: #64748B;
        margin: 0.75rem 0;
      }

      &-date {
        font-size: 0.875rem;
        color: #94A3B8;
      }

      .blog__actions {
        margin-top: 0.75rem;
        display: flex;
        gap: 0.75rem;

        button {
          background: none;
          border: none;
          cursor: pointer;
          color: #6366F1;
          transition: color 0.2s;
          &:hover {
            color: #4F46E5;
          }
        }
      }
    }
  }
}
