.home {
  padding: 3rem 1rem;
  max-width: 1100px;
  margin: 0 auto;

  &__hero {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;

    .home__photo {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 auto;
    }

    .home__intro {
      h1 {
        font-size: 2rem;
        color: #334155;

        span {
          color: #6366F1;
        }
      }

      h2 {
        font-size: 1.2rem;
        color: #64748B;
        margin: 0.5rem 0 1rem;
      }

      .home__cta {
        background-color: #6366F1;
        color: white;
        padding: 0.6rem 1.2rem;
        border-radius: 8px;
        text-decoration: none;
        font-weight: 500;
        transition: background-color 0.3s;

        &:hover {
          background-color: #4F46E5;
        }
      }
    }
  }

  &__about {
    margin-bottom: 3rem;

    h2 {
      text-align: center;
      color: #334155;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
      margin-top: 1rem;

      p {
        color: #64748B;
        max-width: 600px;
        text-align: center;
      }

      .home__about-image {
        max-width: 280px;
      }
    }
  }

  &__projects-preview {
    margin-bottom: 3rem;

    h2 {
      text-align: center;
      color: #334155;
      margin-bottom: 2rem;
    }

    .home__projects-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      gap: 1.5rem;

      .project-card {
        background-color: white;
        border-radius: 12px;
        padding: 1rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
        transition: transform 0.3s;

        &:hover {
          transform: translateY(-5px);
        }

        h3 {
          color: #6366F1;
          margin-bottom: 0.5rem;
        }

        p {
          color: #64748B;
          margin-bottom: 1rem;
        }

        a {
          color: #6366F1;
          text-decoration: none;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__skills-link {
    text-align: center;
    margin-top: 3rem;

    h2 {
      color: #334155;
    }

    p {
      color: #64748B;
      margin-bottom: 1rem;
    }

    .home__cta {
      background-color: #6366F1;
      color: white;
      padding: 0.6rem 1.2rem;
      border-radius: 8px;
      text-decoration: none;
      font-weight: 500;
      transition: background-color 0.3s;

      &:hover {
        background-color: #4F46E5;
      }
    }
  }

  // Responsive
  @media (max-width: 768px) {
    &__hero {
      grid-template-columns: 1fr;
      text-align: center;

      .home__intro {
        h1, h2 {
          text-align: center;
        }
      }
    }

    &__about-content {
      p {
        text-align: center;
      }
    }
  }
}
