.about {
  padding: 3rem 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.05);
  max-width: 900px;
  margin: 2rem auto;

  &__title {
    font-size: 2rem;
    text-align: center;
    color: #334155;
    margin-bottom: 1.5rem;
  }

  &__intro {
    text-align: center;
    color: #64748B;
    margin-bottom: 2rem;
  }

  &__section {
    margin-bottom: 2.5rem;

    .about__heading {
      font-size: 1.5rem;
      color: #6366F1;
      margin-bottom: 1rem;
    }

    .about__list {
      list-style: none;
      padding-left: 0;

      .about__item {
        margin-bottom: 1.5rem;

        h3 {
          font-size: 1.125rem;
          color: #334155;
        }

        span {
          display: block;
          font-size: 0.875rem;
          color: #94A3B8;
        }

        p {
          color: #64748B;
          margin-top: 0.25rem;
        }
      }
    }
  }
}
