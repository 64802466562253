.faq {
  padding: 3rem 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.05);
  max-width: 800px;
  margin: 2rem auto;

  &__title {
    font-size: 2rem;
    text-align: center;
    color: #334155;
    margin-bottom: 2rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .faq__item {
      background-color: #F9FAFB;
      border-radius: 8px;
      padding: 1rem;

      .faq__question {
        background: none;
        border: none;
        width: 100%;
        text-align: left;
        font-weight: 600;
        color: #6366F1;
        cursor: pointer;
        font-size: 1rem;
        transition: color 0.3s ease;

        &:hover {
          color: #4F46E5;
        }
      }

      .faq__answer {
        color: #64748B;
        margin-top: 0.75rem;
        font-size: 0.95rem;
        line-height: 1.6;
      }
    }
  }
}
