*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: $font-main;
  font-size: 16px;
  background-color: $bg-dark;
  color: $text-light;
  line-height: 1.6;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}
